.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gridWrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-auto-rows: minmax(100px, auto);
}

.topPane{
  grid-column: 1 / 5;
  grid-row: 1;
}
.rightPane { 
  grid-column: 2 / 4;
  grid-row: 1 / 3;
}
.leftPane {
  grid-column: 1;
  grid-row: 2 / 5;
}

.wrapper{
  max-width: 480px;
  margin: auto;
  background: #fff;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
}

.googleBtn {
  width: 225px;
  margin: 0 0 50px 0;
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.googleBtn img {
  width: 16px;
  height: 16px;
  padding: 0;
  margin: 0 5px;
  vertical-align: middle;
}

* {box-sizing: border-box}

.topbar {
  background-color: #0078d4;
}

.sidebar {
  float: left;
  /* border: 1px solid #ccc; */
  background-color: #ffffff;
  width: 300px;
  height: calc(100vh - 44px);
}

.content {
  padding: 25px;
  margin-left: 300px;
  background-color: #f1f1f1;
  height: calc(100vh - 44px);
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 50px;
  }
  .sidebar a {float: left;}
  .content {margin-left: 50px;}
}